<template>
  <div class="page">
    <div class="box">
      <div class="head-title align-center justify-center">
        <img src="@/assets/img/serviceAgreement_title.png" alt="">
      </div>
      <div class="text-box">
        <h4 class="text">
          欢迎使用大屏时代的会员服务，为了保障您的权益，请在进行下一步操作前，请您仔细阅读、理解并遵守《大屏时代会员服务协议》（简称为：本协议），
          当您勾选”同意《会员服务协议》“或“立即开通”按钮或其他具有类似含义的按钮时，
          您的行为表示您同意并签署了本协议，并同意遵守本协议中的约定。该协议构成您与大屏时代达成的协议，具有法律效力。
        </h4>
        <h4 class="text">
          如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称"监护人"）的陪同下阅读和判断是否同意本协议，并特别注意未成年人使用条款。
        </h4>
        <h3 class="title-text">一、提供的会员服务</h3>
        <h4 class="text">
          1.主要包括每月赠送的免费时长，每月5000秒1折优惠等身份特权，具体服务以我们实际提供的为准。<br>
          2.您可通过多种客户端使用本服务，但您理解并同意，因客户端兼容性不同、展示效果不同、经营策略安排或调整等原因，
          不同客户端的用户可使用的本服务的具体内容可能会存在差异，具体以我们实际提供的为准。<br>
          3.我们提供的服务为收费服务，使用该服务前您须支付对应的服务费用。收费模式为将本服务中的全部服务打包，根据不同的使用期限、不同的客户端以及不同的套餐制定收费标准向您收取费用；
        </h4>
        <h3 class="title-text">二、使用规则</h3>
        <h4 class="text">
          1.会员服务有效期根据您选择购买的会员选项的具体时长为准，自您成功支付会员服务费用之日起计算。
          每一个收费项目的服务周期以页面标注期限为准。如您未在有效时间内使用已购买的服务，视为您已使用，大屏时代概不退款。
          若您希望在有效期届满后继续享受会员服务，则需要重新购买。
          <br>
          2.大屏时代保留在法律法规允许的范围内自行决定是否接受您的会员申请、调整会员服务内容、取消会员资格等相关权利。
          <br>
          3.您理解并同意大屏时代有权根据实际业务需要不时地调整其网站内容而无需另行通知您，包括但不限于内容增减、授权期限、收费模式等等。
        </h4>
        <h3 class="title-text">三、用户传播的内容与投诉处理</h3>
        <h4 class="text">
          1.您通过本服务发送或传播的内容（包括但不限于图片、音频、视频等）均由您自行承担责任。
          <br>
          2.您发送或传播的内容应有合法来源，相关内容为您所有或您已获得必要的授权。
          <br>
          3.如果您发送或传播的内容违法违规或侵犯他人权利的，大屏时代有权进行独立判断并采取删除、屏蔽或断开链接等措施。
          <br>
          4.如您被他人投诉或您投诉他人，大屏时代有权将争议中相关方的主体信息、联系方式、
          投诉相关内容等必要信息提供给相关当事方或相关部门，以便及时解决投诉纠纷，保护各方合法权益。
          <br>
          5.您保证对您在投诉处理程序中提供的信息、材料、证据等的真实性、合法性、有效性负责。
        </h4>
        <h3 class="title-text">四、会员服务售后</h3>
        <h4 class="text">
          1.如您有与会员服务售后相关的问题，可以通过公布的联系方式联系客服进行反馈。
        </h4>
        <h3 class="title-text">五、法律管辖</h3>
        <h4 class="text">
          1.本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。<br>
          2.如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向大屏时代所在地有管辖权的人民法院提起诉讼。
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  min-height: calc(100vh - 233rem);
  padding-top: 48rem;
  padding-bottom: 100rem;

  .box {
    width: 1280rem;
    margin: 0 auto;
    padding-bottom: 80rem;
    background: #ffffff;
  }

  .head-title {
    height: 213rem;
    background: url('../../assets/img/topBack.png');
    background-size: 100% 100%;

    img {
      width: 367rem;
      height: 65rem;
    }
  }

  .text-box {
    width: 856rem;
    margin: 0 auto;

    .text {
      margin: auto;
      font-size: 20rem;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #2D2D2D;
      line-height: 28rem;
      margin-bottom: 25rem;
    }

    .title-text {
      margin: auto;
      height: 28rem;
      font-size: 20rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #2D2D2D;
      line-height: 28rem;
      margin-bottom: 15rem;
    }
  }
}
</style>
